<mat-form-field  fxLayout="column" appearance="outline">
  <mat-label><span class="mandatory-field" *ngIf="phoneRequired">* </span>Numéro de téléphone où nous pouvons vous joindre</mat-label>
    <input matInput
           [required]="phoneRequired"
           name="telephone"
           placeholder="Numéro de téléphone où nous pouvons vous joindre"
           class="form-control"
           [formControl]="control"
           fxFlex="100%">
</mat-form-field>
