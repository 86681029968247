import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  /**
   * Renvoie le nombre de années / mois / jours / heures / mn / s pour un nombre de secondes données
   * https://blog.prepscholar.com/how-many-seconds-in-a-day-a-month-a-year
   * @param secondsInput
   */
  static secondsToYMWDHMS(secondsInput: number) {
    const [YEAR, MONTH, WEEK, DAY, HOUR, MINUTE] = [31536000, 2628000, 604800, 86400, 3600, 60];
    const _seconds = Number(secondsInput);
    const years = Math.floor(_seconds / YEAR);
    const months = Math.floor((_seconds % YEAR) / MONTH);
    const weeks = Math.floor((_seconds % YEAR) % MONTH) / WEEK;
    const days = Math.floor(((_seconds % YEAR) % MONTH) / DAY);
    const hours = Math.floor((_seconds % DAY) / HOUR);
    const minutes = Math.floor((_seconds % HOUR) / MINUTE);
    const seconds = Math.floor(_seconds % MINUTE);
    const ymdhms = {years, months, weeks, days, hours, minutes, seconds};
    return {...ymdhms};
  }
  static YMWDHMSToseconds(duration = {years: 0, weeks: 0, months: 0, days: 0, hours: 0, minutes: 0, seconds: 0}) {
    // 30.417 : moyenne de jours par mois dans une année
    const totalDays = (duration['years'] * 365) + (duration['months'] * 30.417) + (duration['weeks'] * 7) + duration['days'];
    const totalHours = (totalDays * 24) + duration['hours'];
    const totalMinutes = (totalHours * 60) + duration['minutes'];
    return Math.floor(totalMinutes * 60);
  }

  /**
   * Passe une date 20311231 en 31/12/2031
   * @param {string} entryDate
   * @constructor
   */
  static YYYYMMDDToDDMMYYYY(entryDate: string) {
    if (entryDate) {
      const year = entryDate.substring(0, 4);
      const month = entryDate.substring(4, 6);
      const day = entryDate.substring(6, 8);
      return `${day}/${month}/${year}`;
    }
    return '';
  }
  /**
   * Génére une séquence unique (faite de chiffres et de lettres)
   * @param {any} length : la longueur souhaitée, par défaut 8
   * @return {string} la séquence générée
   */
  static generateSequence(length = 8) {
    let str = '';

    for (let i = 1; i < length + 1; i = i + 8) {
      str += Math.random().toString(36).substring(2, 10);
    }

    return (str).substring(0, length);
  }

  /**
   * La date du jour au format YYY-MM-DD
   * @param {string} locale : la locale courante
   * @param {Date} date : la date courante, par défaut du jour
   * @param {string} format : le format par défaut
   */
  static dateOftheDay(locale: string, date = new Date(), format: string = 'yyyy-MM-dd') {
    const dp = new DatePipe(locale);
    return dp.transform(date, format);
  }

  /**
   * Nombre de jours pour un mois donné
   * @param {number} month : 1 à 12
   * @param {number} year : année
   * @return {any}
   */
  static daysInMonth(month: number, year: number) {
    return new Date(year, month, 0).getDate();
  }

  static addDays(dt, daysToAdd) {
    return new Date(dt).getTime() + (60 * 60 * (24 * daysToAdd) * 1000);
  }

  /**
   * 1ère lettre en capitale
   * @param word
   * @param notrim
   * @constructor
   */
  static ucFirst(word: string, notrim = false) {
    const s = notrim ? word : (word ? word.replace(/(?:(?:^|\n)\s+|\s+(?:$|\n))/g, '').replace(/\s+/g, ' ') : '');
    return s && s.length > 0 ? s.charAt(0).toUpperCase() + s.slice(1) : s;
  }
}
