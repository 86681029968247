import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ExtraFieldsEnum, TicketTypeEnum} from './extra-fields.enum';
import {EventExtraFieldValue} from './event-extra-field-value';
import {MatButtonToggleChange} from '@angular/material/button-toggle';
import {TicketService} from '../../services';
import {SubSink} from '@Common/core/utils/subsink';
import {ParameterService} from '../../services/parameter/parameter.service';
import {Parameter} from '../../services/parameter/parameter';
import {UntypedFormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';

/**
 * champs extra_fields
 */
@Component({
  selector: 'app-extra-fields',
  templateUrl: './extra-fields.component.html',
  styleUrls: ['./extra-fields.component.scss']
})
export class ExtraFieldsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() field: ExtraFieldsEnum;
  @Input() isRequired: boolean;
  @Output() valueUpdated = new EventEmitter<EventExtraFieldValue>();
  extraFields = ExtraFieldsEnum;
  sites: Map<number, string>;
  siteSelected: number;
  officeControl = new UntypedFormControl();
  office: string;
  ticketTypeSelected: TicketTypeEnum;
  ticketTypeEnum = TicketTypeEnum;
  subSink = new SubSink();
  constructor(private ticketSvc: TicketService,
              private paramSvc: ParameterService) { }

  ngOnInit(): void {
    this._initOffice();
  }
  onTicketTypeChanged(value: MatButtonToggleChange) {
    const _eventEmit = <EventExtraFieldValue> {
      type: ExtraFieldsEnum.ticket_type,
      value: value.value
    };
    this.valueUpdated.emit(_eventEmit);
  }
  onSiteChanged(value) {
    const _eventEmit = <EventExtraFieldValue> {
      type: ExtraFieldsEnum.site,
      value: value
    };
    this.valueUpdated.emit(_eventEmit);
  }
  private _initOffice() {
    this.subSink.sink = this.officeControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged())
      .subscribe((_value: string) => {
        const _eventEmit = <EventExtraFieldValue> {
          type: ExtraFieldsEnum.office,
          value: _value
        };
        this.officeControl.setValue(_value);
        this.valueUpdated.emit(_eventEmit);
      });
  }
  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  ngAfterViewInit(): void {
    if (this.field === ExtraFieldsEnum.site) {
      this.subSink.sink = this.paramSvc
        .getByName('sites')
        .subscribe((_sites: Parameter[]) => {
          if (_sites && _sites.length > 0) {
            this.sites = new Map(_sites[0].settings.map((_site) => [_site.id, _site.name]));
          }
        });
    }
  }
}
