<mat-card fxLayout="column" class="wrapper-new-ticket">
  <mat-card-title>
    <p8-layout-settings-header [titlePath]="['Pour saisir votre demande, renseignez le formulaire ci-dessous']">
      <div fxLayoutAlign="end" style="font-size: small; font-weight: normal;" after-card>
        <mat-hint>les champs marqués par un <strong class="mandatory-field">*</strong> sont obligatoires</mat-hint>
      </div>
    </p8-layout-settings-header>
  </mat-card-title>
  <mat-card-content>
    <app-form-create-ticket
      (onSaved)="redirect()"
      (onCanceled)="redirect()"
      [typeForm]="formType.contact"
      [labelButton]="'ENVOYER VOTRE DEMANDE'">
    </app-form-create-ticket>
  </mat-card-content>
</mat-card>
