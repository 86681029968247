
<!-- incident ou demande ? -->
<ng-container *ngIf="field==extraFields.ticket_type">
  <div class="button-toggle-container">
    <mat-label><span class="mandatory-field" *ngIf="isRequired">*</span>&nbsp;</mat-label>
    <mat-button-toggle-group name="ticket_type"
                             aria-label="Type de ticket"
                             appearance="standard"
                             [(ngModel)]="ticketTypeSelected"
                             (change)="onTicketTypeChanged($event)">
      <mat-button-toggle [value]="ticketTypeEnum.incident"
                         matTooltip="Ticket lié à un incident, problème technique, cliquer ici"
                         class="button-toggle-ano">
        <mat-icon>medical_services</mat-icon> Incidents, pannes, problème technique
      </mat-button-toggle>
      <mat-button-toggle [value]="ticketTypeEnum.service"
                         matTooltip="Ticket lié à une demande de service, cliquer ici"
                         class="button-toggle-demand">
        <mat-icon>support_agent</mat-icon> Demande de service
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</ng-container>

<!-- Site où se situe le ticket -->
<ng-container *ngIf="field==extraFields.site">
  <mat-expansion-panel [expanded]="true" style="margin-top: 5px;">
    <mat-expansion-panel-header>
      <mat-panel-title><span class="mandatory-field" *ngIf="isRequired">* &nbsp;</span>Site concerné</mat-panel-title>
      <mat-panel-description>sur quel site se situe le problème ou la demande</mat-panel-description>
    </mat-expansion-panel-header>
    <mat-select placeholder="Sélectionner le site"
                [(ngModel)]="siteSelected"
                (valueChange)="onSiteChanged($event)">
      <mat-option *ngFor="let site of sites | keyvalue" [value]="site.key">
        {{ site.value }}
      </mat-option>
    </mat-select>
  </mat-expansion-panel>
</ng-container>

<!-- Bureau -->
<ng-container *ngIf="field==extraFields.office">
  <mat-form-field fxLayout="column" appearance="outline">
    <mat-label><span class="mandatory-field" *ngIf="isRequired">* </span>Votre bureau</mat-label>
    <input matInput
           [UpperCaseFirstLetter]="true"
           [formControl]="officeControl"
           [required]="isRequired"
           name="bureau"
           placeholder="G318"
           fxFlex="100%">
  </mat-form-field>
</ng-container>
