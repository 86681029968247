export enum parameters {
  // parametres
  orniPause = 'orni-pause',
  orniActive = 'orni-active',
  notifNew = 'notif-new',
  notifDelay = 'notif-delay',
  refresh = 'refresh',
  autoClosed = 'auto_closed',
  descriptionHelp = 'description-help-ticket',
  thresholds_duration = 'thresholds_duration',
  notifDelayBeforeArchiving = 'notif-delay-before-archiving',

  // formulaire demandeur
  phoneRequired = 'phone-required',
  categorieRequired = 'categorie-required',
  filesRequired = 'files-required',
  contactsRequired = 'contacts-required',
  typeRequired = 'type-required',
  siteRequired = 'site-required',
  officeRequired = 'office-required',
}

export enum parameterLabels  {
  'phone-required' =  'N° téléphone obligatoire',
  'categorie-required' =  'Catégorie obligatoire',
  'files-required' =  'Pj obligatoire',
  'contacts-required' =  'Contacts obligatoires',
  'site-required' = 'Site obligatoire',
  'type-required' =  'Type de ticket obligatoire',
  'office-required' = 'Bureau obligatoire',
}
